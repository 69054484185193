import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../images/new-home-screenshot.png';
import SvgCharts from '../svg/SvgCharts';
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import creating_pathways from '../images/creating_pathways.png';
import playback_pathways from '../images/playback_pathways.png';
import interactive_zoom from '../images/interactive_zoom.png';
import pruning from '../images/pruning.png';
import exploration from '../images/exploration.png';
import rss from '../images/rss.png';
import ontology from '../images/ontology.png';
import merge from '../images/merge.png';
import keywords from '../images/keywords.png';
import { Helmet } from "react-helmet"

export default () => (
<>
<Helmet title="VUE - Features" defer={false} />
<Layout>
<section id="features" className="py-20 lg:pb-40">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-3xl font-semibold">Enhanced Pathways and Presentation Tools</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Creating Pathways</p>
              <p className="mt-4">
                Adding a node to a pathway now produces a slide where node content can be arranged for presentation purposes. Authors may edit the slide view directly on the map by zooming in to the slide thumbnail and selecting the editing tools, without impacting the underlying map.
              </p>
              <img src={creating_pathways}/>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Playback Pathways</p>
              <p className="mt-4">
                The Playback tool launches VUE into full-screen presentation mode. Presenters can use the mouse, arrow keys or space bar to move through a presentation. Images can be zoomed in via a single click without exiting the presentation, and a map overlay may be displayed by pressing the "M" key. Presenters can also zoom out to the map and select any node as the focus. By moving the cursor to the far right of the screen during a presentation, presenters can show any content linked to a node on the presentation pathway.
              </p>
              <img src={playback_pathways}/>
            </Card>

          </div>          
        </div>
      </div>
      <div className="container mx-auto text-center">        
      	<h2 className="text-3xl lg:text-3xl font-semibold">Visualization</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Interactive Zoom</p>
              <p className="mt-4">
                By using VUE’s new presentation zoom tool, authors may quickly zoom any node to full-screen mode with a single click. In addition, clicking while holding down the shift key will further zoom in to the image content of the selected node. Note that content zooming is only animated while in full-screen mode. This feature enables presenters to quickly explore the details of high-resolution images that are embedded in VUE nodes.
              </p>
               <img src={interactive_zoom}/>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Visual Pruning</p>
              <p className="mt-4">
                VUE links now present a basic toggle element to allow users to collapse and expand branches of nodes.
              </p>
              <img src={pruning}/>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Exploration Tool</p>
              <p className="mt-4">
                The exploration tools provide dynamic ways to select and fade out nodes and links, in addiiton to zooming in and out of the map during presentations.
              </p>
              <img src={exploration}/>
            </Card>
          </div>                   
        </div>
      </div>
      <div className="container mx-auto text-center">        
      	<h2 className="text-3xl lg:text-3xl font-semibold">Dynamic Content Mapping</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">RSS / CSV Import</p>
              <p className="mt-4">
                Datasets (CSV) and XML feeds (RSS) can be imported into VUE. VUE can help analyze and visualize the data.
              </p>
              <img src={rss}/>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Ontologies</p>
              <p className="mt-4">
                Authors may now import ontologies defined in RDF-S or OWL formats directly into VUE, allowing for the creation of concept maps from pre-defined object and relationship types. A defined mapping vocabulary scaffolds map creation and supports computer-assisted map comparison and assessment. The visual characteristics of objects and relationships defined in an ontology may also be styled via a CSS file.
              </p>
              <img src={ontology}/>
            </Card>
          </div>         
        </div>
      </div>
  	  <div className="container mx-auto text-center">        
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
 		  <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Merge Maps</p>
              <p className="mt-4">
                VUE supports the merging of a set of maps into a single map and offers two modes for visualizing nodes and links that are shared across maps: vote or weight. Vote enables users to set a threshold to show nodes and links that meet a specific requirement (e.g., nodes and links that are in at least 35% of the maps). The weight mode color codes the nodes based on frequency of occurrence. Using an ontology is not required to use the merge maps tools, as the merging is currently based on node and link labels. VUE 2.0 will allow maps to be merged on unique IDs or a combination of a node's metadata.
              </p>
              <img src={merge}/>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Keywords</p>
              <p className="mt-4">
                The Keywords tool enables authors to "tag" or apply full metadata schema to nodes and links. Maps can then be searched based on these keywords (see below).
              </p>
              <img src={keywords}/>
            </Card>
          </div>          
        </div>
      </div>
      <div className="container mx-auto text-center">        
      	<h2 className="text-3xl lg:text-3xl font-semibold">Other Features</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Search</p>
              <p className="mt-4">
                The search tool allows to query nodes and links in large maps. Search results can be highlighted, hidden or filtered.
              </p>
            </Card>
          </div>          
        </div>
      </div>
    </section>
 
</Layout>
</>
)
